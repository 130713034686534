import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default ({ data }) => {

  return (
    <Layout>
      <Helmet 
        meta={[
          { name: 'description', content: 'Clearinghouse Navigator Terms of Use' },
          { name: 'keywords', content: 'Clearinghouse Navigator,  Terms of Use' },
        ]}
      />  
      <SEO title="Terms of Use" />
        <div className="container">
          <div className="row">
            <div className="col-md-auto py-5">
              <h2  dangerouslySetInnerHTML={{ __html: data.nodePage.title }} ></h2>
              <div className="text-left" dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }} />
              
            </div>
          </div>
        </div>
    </Layout>
  )
}

export const query = graphql` 
  query {
    nodePage(id: {eq: "2d0ae21c-f097-5983-9dc7-8777a5f40ee2"}) {
      id
      title
      body {
        value
      }
    } 
  } `

